<template>
    <div class="home-page">
        <Page1 />
    </div>
</template>

<script>
import Page1 from "./components/Page1/index"
export default {
    name: "Home",
    components: { Page1 },
    data() {
        return {
            // 轮播位置
            swiperIndex: 0,
            maxIndex: 4,
            moving: false,
            height: 0,
            startTime: 2000,
            endTime: 0,
            Max: [0, 1, 2, 3],
            show: false,
        }
    },
}
</script>

<style lang="scss" scoped>
.home-page {
    width: 100%;
    overflow: hidden;
}
</style>